import MainLayout from '@components/layout/MainLayout'
import { useSearch } from '@contexts/search'
import { Booking, ListingsResponse, StatusType } from '@service/booking.types'
import { isToday } from 'date-fns'
import type { NextPage } from 'next'
import React, { useEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'
import { defaultDelivery, disabledHomePage, highlightBookingsCount } from '@util/config'
import { CitySettingsResponse, ProductType } from '@service/configuration.types'
import { GATrackingActions, GATrackingCategories, MarketingBannerType } from '@util/enums'
import { BookingActionKind, useGlobalState } from '@contexts/global'
import { useVerification } from '@hooks/useVerification'
import { device } from '@util/responsive'
import { useMediaQuery } from '@hooks/mediaQuery'
import { useDateCalculation } from '@hooks/useDateCalculation'
import { useScroll } from '@hooks/useScroll'
import useMountEffect from '@hooks/useMountEffect'
import { MobileBookingQuickFilter } from '@components/modules/Search/MobileBookingQuickFilter/MobileBookingQuickFilter'
import { useRouter } from 'next/router'
import { CarList } from '@components/modules/Cars/CarList'
import { DesktopProductSwitcher } from '@components/modules/Search/DesktopProductSwitcher'
import { useListingQuery } from '@components/modules/Car/useListingQuery'
import debounce from 'lodash/debounce'
import { useCommon } from '@contexts/common'
import dynamic from 'next/dynamic'
import { getISODate } from '@util/functions'
import { MetaTags } from '@components/Meta'
import { useCookie } from '@hooks/useCookie'
import { Filter } from '@components/modules/Car/Filter'
import { trackEvent } from '@util/ga'
import { useAuth } from '@contexts/auth'
import { CurrentBookingsWidget } from '@components/CurrentBookingsWidget'

const DateTimeBar = dynamic(
    () => import('@components/modules/Search/DateTimeBar').then(({ DateTimeBar }) => DateTimeBar),
    { ssr: false },
)

const MarketingBanner = dynamic(
    () => import('@components/global/MarketingBanner').then(({ MarketingBanner }) => MarketingBanner),
    { ssr: false },
)

const Cars: NextPage<{ cars: ListingsResponse; citySettings: CitySettingsResponse }> = () => {
    const { bookingDispatch, bookingState } = useGlobalState()
    useDateCalculation()
    const { bookings } = useVerification()
    const { changeProductTo } = useCommon()
    const canonical = window.location.href.split('?')[0]
    const { listingFilter: listingsParams } = useListingQuery()
    const { datePickerOverlay, setDatepickerOverlay, fromDate, toDate, fromPickerTime, toPickerTime } = useSearch()
    const { push, query, pathname } = useRouter()
    const { user } = useAuth()

    const highlightBookingsRef = useRef<HTMLDivElement>(null)
    const { isScrolled } = useScroll(200)
    const isDesktop = useMediaQuery(device.laptop)

    const debouncedCheckScroll = debounce(() => isScrolled, 10)

    // get value of ksa_user cookie
    const ksaUser = useCookie('ksa_user')
    const activeBookings = useMemo(() => {
        if (bookings?.length > 0) {
            const waitingApproval = bookings.filter(
                (booking: Booking) => booking.status === StatusType.PENDING_APPROVAL,
            )
            const active = bookings.filter(
                (booking: Booking) =>
                    booking.status === StatusType.ACTIVE &&
                    booking.handbackTime &&
                    !isToday(new Date(booking.handbackTime)),
            )

            const returnAt = bookings.filter(
                (booking: Booking) =>
                    booking.status === StatusType.ACTIVE &&
                    booking.handbackTime &&
                    isToday(new Date(booking.handbackTime)),
            )
            const deliveryAt = bookings.filter(
                (booking: Booking) => booking.status === StatusType.APPROVED && isToday(new Date(booking.handoverTime)),
            )

            const approved = bookings.filter(
                (booking: Booking) =>
                    booking.status === StatusType.APPROVED && !isToday(new Date(booking.handoverTime)),
            )

            const result = [...waitingApproval, ...approved, ...active, ...deliveryAt, ...returnAt]
            return result.slice(-highlightBookingsCount)
        }

        return null
    }, [bookings])

    const isThereActiveBooking = activeBookings && activeBookings.length > 0

    const handleTrackingEvents = () => {
        const hasTrackedFirstOpen = sessionStorage.getItem('hasTrackedFirstOpen')

        if (!hasTrackedFirstOpen) {
            trackEvent({
                action: GATrackingActions.H_FIRST_OPEN,
                category: GATrackingCategories.HOMEPAGE,
                value: {
                    userId: user?.userId || '',
                    careemId: user?.careemId || '',
                    isDesktop: isDesktop,
                },
            })

            sessionStorage.setItem('hasTrackedFirstOpen', 'true')
        }

        trackEvent({
            action: GATrackingActions.H_OPEN,
            category: GATrackingCategories.HOMEPAGE,
            value: {
                userId: user?.userId || '',
                careemId: user?.careemId || '',
                isDesktop: isDesktop,
            },
        })
    }

    useMountEffect(() => {
        handleTrackingEvents()

        bookingDispatch({
            type: BookingActionKind.CHANGE_DELIVERY_TYPE,
            value: defaultDelivery,
        })
        changeProductTo(bookingState.productType)
    })

    const myRef = useRef()

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    useEffect(() => {
        // URL change for only product type switching
        if (fromDate && fromPickerTime) {
            const handoverDateTime = getISODate(fromDate, fromPickerTime?.startDate)

            if (toDate && toPickerTime) {
                const handbackDateTime = getISODate(toDate, toPickerTime.startDate)
                const {
                    maxDailyPrice,
                    minDailyPrice,
                    carType,
                    productType,
                    makeIds,
                    modelIds,
                    engineTypes,
                    gearboxTypes,
                    carFeatureIds,
                    ...routerQuery
                } = query

                const queryParams = {
                    ...routerQuery,
                    from: handoverDateTime,
                    to: handbackDateTime,
                    productType: bookingState.productType,
                }

                push(
                    {
                        pathname,
                        query: queryParams,
                    },
                    undefined,
                    { shallow: true },
                )
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingState.productType])

    useEffect(() => {
        if (fromDate && fromPickerTime) {
            const handoverDateTime = getISODate(fromDate, fromPickerTime?.startDate)

            if (toDate && toPickerTime) {
                const handbackDateTime = getISODate(toDate, toPickerTime.startDate)

                const queryParams = {
                    ...query,
                    from: handoverDateTime,
                    to: handbackDateTime,
                    productType: bookingState.productType,
                }

                push(
                    {
                        pathname,
                        query: queryParams,
                    },
                    undefined,
                    { shallow: true },
                )
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fromDate, toDate, fromPickerTime, toPickerTime])

    return (
        <MainLayout showBackButton={!disabledHomePage} noWarning={!isDesktop}>
            <MetaTags pageType={'listings'} canonical={canonical} />
            {!isDesktop && isScrolled && (
                <StickyDateTime scrolled={isScrolled} marginTop={highlightBookingsRef.current?.clientHeight || 0}>
                    <DateTimeBar
                        scrolled
                        isOnlyHandover={bookingState.productType === ProductType.MONTHLY}
                        onClick={() => {
                            scrollToTop()
                            trackEvent({
                                action: 'Datetime-open',
                                category: 'BrowseDR',
                                label: 'Browse',
                            })
                        }}
                    />
                </StickyDateTime>
            )}

            <Wrapper
                marginTop={0}
                onClick={() => datePickerOverlay && setDatepickerOverlay(false)}
                scrolled={isScrolled}
            >
                <CurrentBookingsWidget />
                {listingsParams.cityCode && ksaUser !== 'true' && (
                    <MarketingBanner type={MarketingBannerType.Main} cityCode={listingsParams.cityCode} />
                )}
                {ksaUser === 'true' && listingsParams.cityCode && (
                    <MarketingBanner type={MarketingBannerType.KSAVisitor} cityCode={listingsParams.cityCode} />
                )}
                {isDesktop && <DesktopProductSwitcher />}
                {!isDesktop && !debouncedCheckScroll() && (
                    <MobileBookingQuickFilter isThereActiveBooking={isThereActiveBooking} />
                )}
                {listingsParams.cityCode && (
                    <MarketingBanner type={MarketingBannerType.Secondary} cityCode={listingsParams.cityCode} />
                )}

                <CarList listingsParams={listingsParams} ref={myRef} />

                <Filter ref={myRef} />
            </Wrapper>
        </MainLayout>
    )
}

type WrapperType = {
    scrolled: boolean
    marginTop?: number
}

const StickyDateTime = styled.div<WrapperType>`
    position: sticky;
    z-index: 15;
    top: 65px;
    margin: ${({ scrolled, marginTop = 0 }) =>
        scrolled ? 'auto' : marginTop ? `${marginTop}px var(--padding)` : `var(--padding)`};
    width: fill-available;
    transition: 0.7s margin;
`

const Wrapper = styled.div<WrapperType>`
    background: linear-gradient(38.76deg, var(--primaryColor) 45.15%, rgba(255, 169, 120, 0.8) 101.71%), white;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    overflow: hidden;
    padding: 0px var(--padding);
    transition: 0.5s all;
    @media ${device.laptop} {
        background: none;
        padding: 0;
    }
`

export default Cars
