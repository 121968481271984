import { useTranslation } from 'next-i18next'
import { CheckCircle } from '@global/icons/CheckCircle'
import React from 'react'
import styled from 'styled-components'
import { StatusType } from '@service/booking.types'
import { ArrowIcon } from '@global/icons/Arrow'
import { Warning } from '@global/icons/Warning'
import Link from 'next/link'
import { useLocaleConfig } from '@contexts/config'
import { VerificationStatus } from '@service/identity.types'

const AVAILABLE_STATUSES: {
    key: string
    i18nKey: string
    i18nKeyD2D?: string
    label: string
    labelD2D?: string
    statusKey?: string
    statusLabel?: string
}[] = [
    {
        key: 'PENDING_PAYMENT',
        i18nKey: 'bookingWidget.status.received',
        label: 'Booking received',
        statusKey: 'bookingWidget.status.received.message',
        statusLabel: 'We have received your booking!',
    },
    {
        key: 'PENDING_APPROVAL',
        i18nKey: 'bookingWidget.status.pendingApproval',
        label: 'Verifying documents',
    },
    {
        key: 'APPROVED',
        i18nKey: 'bookingWidget.status.approved',
        i18nKeyD2D: 'bookingWidget.status.approvedD2D',
        statusKey: 'bookingWidget.status.approved.message',
        statusLabel: 'Confirmed! Your car will be ready at your selected timeslot.',
        label: 'Car is ready for pickup',
        labelD2D: 'Car is ready for delivery',
    },
]

export const VerificationStatusMessage: React.FC<{ verificationStatus?: VerificationStatus; status: StatusType }> = ({
    verificationStatus,
    status,
}) => {
    const { t } = useTranslation()
    const { city } = useLocaleConfig()
    if (status !== 'PENDING_APPROVAL') return null
    switch (verificationStatus) {
        case VerificationStatus.awaitingSubmission:
            return (
                <Link href={`/${city}/verification/`} data-testid="booking-widget_verification-status_link">
                    <StatusWarning>
                        <span data-testid="booking-widget_verification-status-message">
                            {t(
                                'bookingWidget.verification.awaitingSubmission',
                                'Please upload your documents for us to prepare your booking',
                            )}
                        </span>
                        <NextButton>
                            <ArrowIcon color={'var(--white)'} rotate={-90} />
                        </NextButton>
                    </StatusWarning>
                </Link>
            )

        case VerificationStatus.resubmissionRequested:
        case VerificationStatus.declined:
            return (
                <Link href={`/${city}/verification/`} data-testid="booking-widget_verification-status_link">
                    <StatusWarning>
                        <Warning />
                        <span data-testid="booking-widget_verification-status-message">
                            {t(
                                'bookingWidget.verification.declined',
                                "Unfortunately some of your documents are missing or unclear. We'll get in touch to help you sort this out asap.",
                            )}
                        </span>
                        <NextButton>
                            <ArrowIcon color={'var(--white)'} rotate={-90} />
                        </NextButton>
                    </StatusWarning>
                </Link>
            )
        case VerificationStatus.submitted:
            return (
                <StatusMessage>
                    <span data-testid="booking-widget_verification-status-message">
                        {t(
                            'bookingWidget.verification.submitted',
                            "Thank you for updating your documents. It usually takes 2 -3 hours to confirm your booking. We'll get back to you soon!",
                        )}
                    </span>
                </StatusMessage>
            )
        case VerificationStatus.pending:
            return (
                <StatusMessage>
                    <span data-testid="booking-widget_verification-status-message">
                        {t(
                            'bookingWidget.verification.pending',
                            'Processing your documents, this usually takes 2 -3 hours.',
                        )}
                    </span>
                </StatusMessage>
            )
        case VerificationStatus.approved:
            return (
                <StatusMessage>
                    <span data-testid="booking-widget_verification-status-message">
                        {t(
                            'bookingWidget.verification.approved',
                            'Finalizing details and confirming your booking, this should take under an hour.',
                        )}
                    </span>
                </StatusMessage>
            )
        default:
            return null
    }
}

export const Status: React.FC<{
    status: StatusType
    verificationStatus?: VerificationStatus
    isD2D: boolean
    title?: string
}> = ({ status, verificationStatus, isD2D, title }) => {
    const { t } = useTranslation()
    const activeIndex = AVAILABLE_STATUSES.findIndex((item) => item.key === status)
    const activeItem = AVAILABLE_STATUSES[activeIndex]
    if (activeIndex === -1) return null
    return (
        <StatusList marginTop={title ? 10 : 0}>
            {title && <Title>{title}</Title>}
            <VerificationStatusMessage verificationStatus={verificationStatus} status={status} />
            {activeItem.statusKey && (
                <StatusMessage>
                    <span data-testid="booking-widget_status-message">
                        {t(activeItem.statusKey ?? '', activeItem.statusLabel ?? '')}
                    </span>
                </StatusMessage>
            )}
            {AVAILABLE_STATUSES.map((item, index) => {
                const isActive = index < activeIndex
                const isCurrent = index === activeIndex
                const i18Key = isD2D ? item.i18nKeyD2D ?? item.i18nKey : item.i18nKey
                const i18Label = isD2D ? item.labelD2D ?? item.label : item.label

                const statusTestId = () => {
                    if (isCurrent) {
                        return 'booking-widget_booking-status_current'
                    }

                    if (isActive) {
                        return 'booking-widget_booking-status_active'
                    }

                    return 'booking-widget_booking-status_inactive'
                }

                return (
                    <StatusItem key={item.key} active={isActive} current={isCurrent}>
                        <div>
                            <Icon active={isActive}>
                                <div>
                                    {isCurrent ? (
                                        <DashedCircle color={'var(--primaryColor)'} />
                                    ) : (
                                        <CheckCircle
                                            color={isActive ? 'var(--primaryColor)' : 'var(--inactiveColor)'}
                                        />
                                    )}
                                </div>
                            </Icon>
                        </div>

                        <div data-testid={statusTestId()}>{t(i18Key, i18Label)}</div>
                    </StatusItem>
                )
            })}
        </StatusList>
    )
}

const NextButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: var(--primaryColor);
    flex-shrink: 0;
`

const StatusMessage = styled.div`
    font-size: var(--size-16);
    font-weight: var(--weight-bold);
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px 0;
`

const StatusWarning = styled(StatusMessage)`
    background: rgba(255, 88, 35, 0.1);
    border-radius: 12px;
    padding: 16px;
`

const Icon = styled.div<{ active: boolean }>`
    position: relative;
    display: flex;
    align-items: center;
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 12px;
        left: calc(50% - 1px);
        height: 100%;
        bottom: 0;
        width: 2px;
        background-color: ${({ active }) => (active ? 'var(--primaryColor)' : 'var(--inactiveColor)')};
        transform: scale(0.5, 2.1);
        z-index: 13;
    }
    & > div {
        position: relative;
        width: 14px;
        height: 14px;
    }
    & svg {
        position: absolute;
        z-index: 20;
    }
`

const StatusItem = styled.div<{ active: boolean; current: boolean }>`
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
    font-size: ${({ current }) => (current ? 'var(--size-14)' : 'var(--size-12)')};
    font-weight: ${({ current, active }) => (current || active ? 'var(--weight-bold)' : 'var(--weight-regular)')};
    color: ${({ active, current }) => {
        if (current) return 'var(--primaryColor)'
        else if (active) return 'var(--black)'
        else return 'var(--inactiveColor)'
    }};

    svg {
        width: 14px;
        height: 14px;
        background-color: white;
    }
    & > div {
        display: flex;
        align-items: center;
    }

    &:last-child div:after {
        display: none;
    }
`

const DashedCircle = ({ color }: { color: string }) => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" color={color} xmlns="http://www.w3.org/2000/svg">
        <circle
            id="ld"
            cx="7"
            cy="7"
            r="6"
            stroke="currentColor"
            strokeWidth={'2'}
            fill={'white'}
            strokeDasharray="3 3"
            clipPath="url(#clip)"
        />
        <clipPath id="clip">
            <use xlinkHref="#ld" />
        </clipPath>
    </svg>
)

const StatusList = styled.div<{ marginTop?: number }>`
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: ${({ marginTop = 10 }) => `${marginTop}px`};
    position: relative;
`

const Title = styled.div`
    font-size: 12px;
    color: #808080;
    font-weight: var(--weight-bold);
    text-transform: uppercase;
`
