import React from 'react'
import { Booking } from '@service/booking.types'
import { useTranslation } from 'next-i18next'
import { ClockIcon } from '@global/icons/Clock'
import { displayTimeRange, getDefaultExtraHours } from '@util/functions'
import { MapPin } from '@global/icons/MapPin'
import Link from 'next/link'
import { WrenchIcon } from '@global/icons/Wrench'
import {
    Badge,
    BoldText,
    CarComponent,
    CardContentWapper,
    CtaButton,
    DateRow,
    DateSection,
    Delivery,
    getFormattedAddress,
    Message,
    Row,
    Separator,
    Text,
    Title,
} from '@components/CurrentBookingsWidget/styles'
import { useLocaleConfig } from '@contexts/config'
import { getGoogleMapLink } from '.'
import { ExtendButton } from '@components/CurrentBookingsWidget/ExtendButton'
import { useRouter } from '@hooks/useRouter'

export const ActiveBookingMobileView: React.FC<{ booking: Booking; isD2D: boolean }> = ({ booking, isD2D }) => {
    const { t } = useTranslation()
    const { city } = useLocaleConfig()
    const { pushWithCity } = useRouter()
    const address = isD2D && booking.d2dHandbackAddress ? booking.d2dHandbackAddress : booking.selfPickupHandbackAddress
    const formattedAddress = getFormattedAddress(address)
    const handbackTime = booking.handbackTime ? new Date(booking.handbackTime) : undefined
    return (
        <>
            <CardContentWapper onClick={() => pushWithCity(`/booking/${booking.bookingId}`)}>
                <Title>
                    <span>{t('bookingWidget.bookingId', 'Booking ID')}:</span>
                    <span> </span>
                    <span data-testid="booking-widget_booking-id">{booking.bookingId}</span>
                    <Badge>{t('bookingWidget.bookingActive', 'Booking active')}</Badge>
                </Title>
                <Message>{t('bookingWidget.enjoyYorRide', 'Enjoy your ride!')}</Message>
                <DateRow>
                    <DateSection date={booking.handoverTime} dataTestId="booking-widget_handover-time" />
                    <Separator />
                    <DateSection date={booking.handbackTime} dataTestId="booking-widget_handback-time" />
                </DateRow>
                <CarComponent carDetails={booking.carDetails} />
            </CardContentWapper>
            <Link href={getGoogleMapLink(address.latitude, address.longitude)}>
                <Delivery>
                    <Title data-testid="booking-widget_delivery-type">{t('bookingWidget.handback', 'Handback')}</Title>
                    {handbackTime && (
                        <>
                            <ClockIcon color={'var(--primaryColor)'} />{' '}
                            <BoldText data-testid="booking-widget_handback-time">
                                {displayTimeRange(handbackTime, getDefaultExtraHours(isD2D))}
                            </BoldText>
                        </>
                    )}
                    <MapPin />
                    <Text>{formattedAddress}</Text>
                </Delivery>
            </Link>
            <Row>
                <ExtendButton booking={booking} city={city} />
                {booking.roadsideAssistanceNumber && (
                    <CtaButton href={`tel:${booking.roadsideAssistanceNumber}`} color={'var(--primaryColor)'}>
                        <WrenchIcon />
                        {t('bookingWidget.roadsideAssistance', 'Roadside Assistance')}
                    </CtaButton>
                )}
            </Row>
        </>
    )
}
