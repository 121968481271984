import React, { useMemo } from 'react'
import { Booking, BookingAddress } from '@service/booking.types'
import { useTranslation } from 'next-i18next'
import { Car } from '@global/icons/Car'
import { useLocaleConfig } from '@contexts/config'
import { CalendarIcon } from '@global/icons/Calendar'
import styled from 'styled-components'

export const CarComponent: React.FC<{ carDetails: Booking['carDetails'] }> = ({ carDetails }) => {
    const { t } = useTranslation()
    const { model, make, modelI18nKey, makeI18nKey } = carDetails ?? {}

    const carName = useMemo(
        () => `${t(makeI18nKey || 'make', make)} ${t(modelI18nKey || 'model', model)}`,
        [make, makeI18nKey, model, modelI18nKey, t],
    )

    return (
        <CarRow>
            <Car color={'var(--primaryColor)'} />
            <span> </span>
            <span data-testid="booking-widget_car-name">{carName}</span>
        </CarRow>
    )
}

export const DateSection: React.FC<{ date: string | null; dataTestId?: string }> = ({ date, dataTestId }) => {
    const { formatDate } = useLocaleConfig()
    return (
        <DateWrapper>
            <CalendarIcon color={'var(--primaryColor)'} />
            <span> </span>
            <span data-testid={dataTestId}>{formatDate(date) || 'Not set'}</span>
        </DateWrapper>
    )
}

export const CardContentWapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`

export const Badge = styled.div<{ color?: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 1px solid ${({ color }) => (color ? color : 'var(--green)')};
    background-color: ${({ color }) => (color ? color : 'var(--green-transparent)')};
    padding: 5px 10px;
    text-transform: none;
    line-height: 12px;
    color: ${({ color }) => (color ? color : 'var(--green)')};
`
export const Message = styled.div`
    font-size: var(--size-16);
    font-weight: var(--weight-bold);
`

export const CtaButton = styled.a<{ color?: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 6px;
    gap: 8px;
    background-color: ${({ color }) => (color ? color : 'var(--green)')};
    border-radius: 20px;
    color: var(--white);
    font-size: var(--size-12);
    font-weight: var(--weight-bold);
`

export const Delivery = styled.div`
    display: grid;
    grid-template-columns: 24px auto;
    flex-direction: column;
    gap: 8px;
`

export const Separator = styled.div`
    width: 20px;
    height: 1px;
    background-color: var(--inactiveColor);
`

export const CarRow = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: var(--size-12);
    color: var(--grey);
`

export const DateWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: var(--size-16);
    font-weight: var(--weight-bold);
`

export const Title = styled.div`
    font-size: 12px;
    color: #808080;
    font-weight: var(--weight-bold);
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-column: auto / span 2;
`

export const DateRow = styled.div<{ maxWidth?: number }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px`};
`

export const Text = styled.div`
    font-size: var(--size-12);
`

export const BoldText = styled.div`
    font-size: var(--size-12);
    font-weight: var(--weight-bold);
`
export const Row = styled.div`
    grid-column: auto / span 2;
    align-items: center;
    display: flex;
    gap: 8px;
    & > a {
        flex: 1 1 auto;
    }
`
export const BadgeWrapper = styled.div<{ isRTL?: boolean }>`
    position: absolute;
    ${({ isRTL }) => (isRTL ? 'left: 16px' : 'right: 16px')};
    font-size: var(--size-12);
`

export const Column = styled.div<{ justify?: string; isRTL?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: ${({ justify = 'space-between' }) => justify};
    gap: 10px;
    padding-left: 5px;
    padding-right: 5px;
    flex: 1;

    ${Title} {
        font-weight: var(--weight-bold);
        a {
            color: rgba(0, 0, 0, 0.5);
        }
    }

    &:not(:last-child) {
        ${({ isRTL }) =>
            isRTL ? 'border-left: 1px solid rgb(0 0 0 / 5%)' : 'border-right: 1px solid rgb(0 0 0 / 5%)'};
    }

    svg {
        max-width: 15px;
    }
`

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`
export const Bottom = styled.div`
    display: flex;
    align-items: center;
    padding-right: 10px;

    gap: 15px;
    a {
        width: 100%;
        height: 35px;
    }
`

export const DesktopWrapper = styled.div`
    display: flex;
    gap: 15px;
`

export function getFormattedAddress(address: BookingAddress) {
    const { addressLine1, addressLine2, city } = address

    return (
        <span data-testid="formatted-address-widget">
            <b data-testid="formatted-address-widget_first-address-line">{addressLine1}</b>
            <span> </span>
            <span data-testid="formatted-address-widget_second-address-line">
                {addressLine2 ? `, ${addressLine2}` : null}
            </span>
            <span>
                , <span data-testid="formatted-address-widget_city">{city}</span>
            </span>
        </span>
    )
}
