import React from 'react'
import { Booking } from '@service/booking.types'
import { useTranslation } from 'next-i18next'
import { useVerification } from '@hooks/useVerification'
import { Status } from '@components/CurrentBookingsWidget/Status'
import { ClockIcon } from '@global/icons/Clock'
import { displayTimeRange, getDefaultExtraHours } from '@util/functions'
import { MapPin } from '@global/icons/MapPin'
import {
    BoldText,
    CarComponent,
    DateRow,
    DateSection,
    Delivery,
    getFormattedAddress,
    Row,
    Separator,
    Title,
    Text,
} from '@components/CurrentBookingsWidget/styles'
import { useLocaleConfig } from '@contexts/config'
import Link from 'next/link'
import { useSwiperAutoHeight } from '@components/CurrentBookingsWidget/hooks'
import { getGoogleMapLink } from '.'
import { ExtendButton } from '@components/CurrentBookingsWidget/ExtendButton'

export const PendingBookingMobileView: React.FC<{ booking: Booking; isD2D: boolean }> = ({ booking, isD2D }) => {
    const { t } = useTranslation()

    const { verificationStatus } = useVerification()
    const address = isD2D && booking.d2dHandoverAddress ? booking.d2dHandoverAddress : booking.selfPickupHandoverAddress
    const formattedAddress = getFormattedAddress(address)
    const { city } = useLocaleConfig()
    useSwiperAutoHeight()
    return (
        <>
            <Title>
                <Link href={`/${city}/booking/${booking.bookingId}`}>
                    <span>{t('bookingWidget.bookingId', 'Booking ID')}:</span>
                    <span> </span>
                    <span data-testid="booking-widget_booking-id">{booking.bookingId}</span>
                </Link>
            </Title>
            <DateRow>
                <DateSection date={booking.handoverTime} dataTestId="booking-widget_handover-time" />
                <Separator />
                <DateSection date={booking.handbackTime} dataTestId="booking-widget_handback-time" />
            </DateRow>
            <CarComponent carDetails={booking.carDetails} />
            <Status
                status={booking.status}
                verificationStatus={verificationStatus}
                isD2D={isD2D}
                title={t('bookingWidget.status', 'Status')}
            />
            <Delivery>
                <Title data-testid="booking-widget_delivery-type">
                    {isD2D ? t('bookingWidget.delivery', 'Delivery') : t('bookingWidget.pickUp', 'Pickup')}
                </Title>
                <ClockIcon color={'var(--primaryColor)'} />
                <BoldText data-testid="booking-widget_handback-time">
                    {displayTimeRange(new Date(booking?.handoverTime || ''), getDefaultExtraHours(isD2D))}
                </BoldText>
                <MapPin />{' '}
                <Link href={getGoogleMapLink(address.latitude, address.longitude)}>
                    <Text>{formattedAddress}</Text>
                </Link>
                <Row>
                    <ExtendButton booking={booking} city={city} />
                </Row>
            </Delivery>
        </>
    )
}
