import { useMyBookings } from '@hooks/api/booking'
import { ActiveBookingWidget } from '@components/CurrentBookingsWidget/ActiveBookingWidget'
import { PendingBookingWidget } from '@components/CurrentBookingsWidget/PendingBookingWidget'
import { Swiper, SwiperSlide } from 'swiper/react'
import styled from 'styled-components'
import { useTranslation } from 'next-i18next'
import { MyBookingsFilter, StatusType } from '@service/booking.types'
import { Pagination } from 'swiper'
import { device, size } from '@util/responsive'

const DEFAULT_FILTER: MyBookingsFilter = {
    statuses: [StatusType.ACTIVE, StatusType.APPROVED, StatusType.PENDING_APPROVAL],
    sortBy: 'UPDATED_AT',
    sortDirection: 'DESC',
}

export const getGoogleMapLink = (lat: string, lon: string): string =>
    `https://www.google.com/maps/search/?api=1&query=${lat}%2C${lon}`

export const CurrentBookingsWidget = () => {
    const { data: currentBookings = [] } = useMyBookings(DEFAULT_FILTER)
    const { t } = useTranslation()

    if (currentBookings.length === 0) return null

    return (
        <WidgetWrapper>
            <div>
                <Title>{t('bookingWidget.yourBookings', 'Your bookings')}</Title>
                <Swiper autoHeight={true} spaceBetween={16} pagination={{ clickable: true }} modules={[Pagination]}>
                    {currentBookings.map((booking) => {
                        const isD2D = !!(booking.d2dHandbackAddress && booking.d2dHandoverAddress)
                        return (
                            <SwiperSlide
                                key={booking.bookingId}
                                style={{ width: '100%', paddingBottom: '30px', textAlign: 'unset' }}
                            >
                                {booking.status == StatusType.ACTIVE ? (
                                    <ActiveBookingWidget key={booking.bookingId} booking={booking} isD2D={isD2D} />
                                ) : (
                                    <PendingBookingWidget key={booking.bookingId} booking={booking} isD2D={isD2D} />
                                )}
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>
        </WidgetWrapper>
    )
}

const WidgetWrapper = styled.div`
    @media ${device.laptop} {
        background-color: var(--primaryColor);

        h2 {
            margin: 0;
            padding-top: 15px;
            padding-bottom: 10px;
        }

        & > div {
            max-width: ${size.container};
            margin: auto;
            padding-inline: var(--desktopPadding);
        }

        .swiper-pagination {
            text-align: center;
        }
    }
`

const Title = styled.h2`
    color: var(--white);
    padding: 0;
    font-weight: 800;
    font-size: 14px;
`
